import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"

import "./services-card.scss"

const ServicesCard = props => {
  return (
    <Link
      to={props.link}
      className="services-card"
      key={props.title.replace(/\s/g, "")}
    >
      <div className="services-card__image-wrap">
        <Image
          className="services-card__image"
          fluid={props.image.childImageSharp.fluid}
          alt={props.imageAlt}
        />
      </div>
      <div className="services-card__info-wrap">
        <h3 className="h4 services-card__title">{props.title}</h3>
        <p className="services-card__description">{props.description}</p>
      </div>
    </Link>
  )
}

ServicesCard.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  imageAlt: PropTypes.string,
}

export default ServicesCard
