import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ServicesCard from "../components/services-card/services-card"
import ContactUs from "../components/contact-us/contact-us"

import SolarSystem from "../components/solar-system/solar-system"

import "../styles/home-page.scss"

const IndexPage = () => {
  const imageServices = useStaticQuery(graphql`
    query {
      imageGanesha: file(relativePath: { eq: "ganesh.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageGuruvayoorappan: file(relativePath: { eq: "guruvayoorappan.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageKali: file(relativePath: { eq: "kali.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageLakshmi: file(relativePath: { eq: "lakshmi.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageShanmugha: file(relativePath: { eq: "shanmugha.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageShiva: file(relativePath: { eq: "shiva.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const services = [
    {
      title: "Horary Prediction (Kavadi)",
      link: "services/horary-prediction",
      description:
        "Find out the cause for suffering in your life and its solution through Horary.",
      image: imageServices.imageKali,
      imageAlt: "Goddess Kali",
    },
    {
      title: "Horoscope Prediction",
      link: "services/horoscope-prediction",
      description:
        "Find out what the future holds for you from your Horoscope.",
      image: imageServices.imageShiva,
      imageAlt: "Lord Shiva",
    },
    {
      title: "Horoscope Writing",
      link: "services/horoscope-writing",
      description: "Get your Horoscope written & read by our experts.",
      image: imageServices.imageLakshmi,
      imageAlt: "Goddess Lakshmi",
    },
    {
      title: "Horoscope Matching",
      link: "services/horoscope-matching",
      description: "Determine if he/she is destined to be your better half.",
      image: imageServices.imageShanmugha,
      imageAlt: "Lord Shanmugha",
    },
    {
      title: "Daivajnas",
      link: "daivajnas",
      description:
        "Consult a Daivajna for your Ashtamangalaprasnam/ Devaprasnam.",
      image: imageServices.imageGuruvayoorappan,
      imageAlt: "Lord Guruvayoorappan",
    },
    // {
    //   title: "Electional Astrology (Muhoortha)",
    //   link: "services/electional-astrology",
    //   description: "Get the auspicious time for your auspicious occasions.",
    //   image: Kalabhairav,
    //   imageAlt: "Goddess Kalabhairav",
    // },
  ]

  return (
    <Layout>
      <SEO title="Home" />
      <section className="hero">
        <h1 className="hero__title decorative">ASHTAMANGALAPRASNAM</h1>
        <h2 className="decorative">THE ULTIMATE SOLUTION</h2>
      </section>
      <section className="section-default about-us">
        {/* <h2 className="h1_l decorative">About Us</h2> */}
        <Image
          className="about-us__image"
          fluid={imageServices.imageGanesha.childImageSharp.fluid}
          alt="Ganesha"
        />
        <p className="section-default__description">
          Our misery is not caused by our sins or lack of skills thereof. It can
          be caused by sins of our past lives or the sins committed by our
          ancestors unintentionally or otherwise. The misery brought about by
          the diseases, career issues, marriage irregularities, problems with
          progeny, infertility, continuous failures in business units etc. can
          be solved. The sins of our past lives can only be rectified by asking
          forgiveness from gods. Hence as the only means for the same,
          ashtamangala prasnam has been conducted since ancestral times.
          Ashtamangala prasnam reveals the good and bad effects of the past,
          present and future and also find out the remedies where ever required
          to remove the malefic effects. Also, it can be used for determining
          the auspicious timings for various activities.
        </p>
      </section>
      <section id="our-services" className="section-default our-services">
        <h2 className="h1_l decorative">Our Services</h2>
        <p className="section-default__description">
          Our expert team of astrologers are here to help you with your
          astrological needs and to lead you to a happy and prosperous life. The
          results are provided in English and Malayalam. If the service you are
          looking for is not listed below please{" "}
          <Link className="link_text" to="/#contact-us" title="Contact Us">
            click here
          </Link>{" "}
          to send us a message so that we could accomodate your request.
        </p>
        <ul className="services-list">
          {services.map(service => (
            <li className="services-list__li">
              <ServicesCard
                title={service.title}
                link={service.link}
                description={service.description}
                image={service.image}
                imageAlt={service.imageAlt}
              ></ServicesCard>
            </li>
          ))}
        </ul>
      </section>
      <ContactUs></ContactUs>
      <SolarSystem></SolarSystem>
    </Layout>
  )
}

export default IndexPage
