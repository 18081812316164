import React from "react"

import { Link, graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

import PrimaryForm from "../primary-form/primary-form"

import Phone from "../../images/icons/phone.svg"

import "./contact-us.scss"

const ContactUs = () => {
  const imageAravind2 = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "aravind-thumb.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <section id="contact-us" className="section-default contact-us">
      <h2 className="h1_l decorative">Get in touch with us</h2>
      <div className="contact-us__main">
        {" "}
        <div className="info-block">
          <div className="info-block__admin">
            <Image
              className="info-block__admin-image"
              fluid={imageAravind2.file.childImageSharp.fluid}
              alt="Aravind Karavattu"
            />
            <h3 className="h5">Aravind Karavattu</h3>
            <h4 className="h6">The Administrator</h4>
            <p>
              Aravind is an expert in Horary and has participated multiple
              Ashtamangalaprasnams. He found this site so that people could get
              access to the services of best Daivajnas and astrologers.
              <br />
              <Link
                to="/administrator"
                className="link_text"
                title="Aravind Karavattu - The Administrator Page"
                tabIndex="0"
              >
                View profile
              </Link>
            </p>
          </div>
          <div className="info-block__address">
            <h4 className="h5">Contact Address</h4>
            <address>
              <p>
                41/2534 Ashtamangalaprasnam
                <br />
                Eranakulam North P.O., Cochin: 18
                <br />
                Kerala, India
              </p>
            </address>
            <a
              className="button_contact"
              href="tel:+919567752287"
              title={"Click to call +91 95677 52287"}
            >
              <img src={Phone} alt="" className="button_contact__icon"></img>
              {"+91 95677 52287".replace(
                /(\d{2})(\d{3})(\d{3})(\d{4})/,
                "$1-$2-$3-$4"
              )}
            </a>
          </div>
          <div className="info-block__facebook">
            <h6>Connect with us in Facebook</h6>
            <a
              target="_blank"
              rel="noreferrer"
              title="Ashtamangalaprasnam Facebook page"
              href="https://www.facebook.com/ashtamangalaprasnam"
              className="button_facebook"
            >
              <svg
                viewBox="0 0 36 36"
                className="a8c37x1j ms05siws hwsy1cff b7h9ocf4"
                fill="url(#jsc_s_2)"
                height="40"
                width="40"
              >
                <defs>
                  <linearGradient
                    x1="50%"
                    x2="50%"
                    y1="97.0782153%"
                    y2="0%"
                    id="jsc_s_2"
                  >
                    <stop offset="0%" stopColor="#0062E0"></stop>
                    <stop offset="100%" stopColor="#19AFFF"></stop>
                  </linearGradient>
                </defs>
                <path d="M15 35.8C6.5 34.3 0 26.9 0 18 0 8.1 8.1 0 18 0s18 8.1 18 18c0 8.9-6.5 16.3-15 17.8l-1-.8h-4l-1 .8z"></path>
                <path
                  className="p361ku9c"
                  fill="#fff"
                  d="M25 23l.8-5H21v-3.5c0-1.4.5-2.5 2.7-2.5H26V7.4c-1.3-.2-2.7-.4-4-.4-4.1 0-7 2.5-7 7v4h-4.5v5H15v12.7c1 .2 2 .3 3 .3s2-.1 3-.3V23h4z"
                ></path>
              </svg>
              fb.com/ashtamangalaprasnam
            </a>
          </div>
        </div>
        <div>
          <p className="mb2">
            Hi, we are happy to hear from you. Send us your query and we will
            contact you.
          </p>
          <PrimaryForm></PrimaryForm>
        </div>
      </div>
    </section>
  )
}

export default ContactUs
