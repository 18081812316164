import PropTypes from "prop-types"
import React from "react"
import "./solar-system.scss"

const SolarSystem = props => (
  <div className="solar-syst">
    {props.hidePlanets ? (
      ""
    ) : (
      <>
        <div className="sun"></div>
        <div className="mercury"></div>
        <div className="venus"></div>
        <div className="earth"></div>
        <div className="mars"></div>
        <div className="jupiter"></div>
        <div className="saturn"></div>
        <div className="uranus"></div>
        <div className="neptune"></div>
        <div className="pluto"></div>
        <div className="asteroids-belt"></div>
      </>
    )}
  </div>
)

SolarSystem.propTypes = {
  hidePlanets: PropTypes.bool,
}

export default SolarSystem
